// If you don't want to use TypeScript you can delete this file!
import * as React from 'react';

import Layout from '../layouts/layout';

const ContactPage = () => (
  <Layout>
    <h1>You wish...</h1>
  </Layout>
);

export default ContactPage;
